import { CountriesList, LOCALSTORAGE_IP_INFO } from "@Constants/index";
import { AlgoliaPackages, AlgoliaSubject } from "types/AlgoliaSearch";

export const htmlDecode = (input) => {
  let doc = new DOMParser().parseFromString(input, "text/html");
  return doc.documentElement.textContent;
};

export const isExternalLink = (link: string) => {
  const tmp = document.createElement("a");
  tmp.href = link;
  return tmp.host !== window.location.host;
};

export const getUrlPathname = (link: string) => {
  const tmp = document.createElement("a");
  tmp.href = link;
  return link.replace(tmp.protocol + "//" + tmp.host, "");
};
function getSubdomain(hostname) {
  let regexParse = new RegExp("[a-z-0-9]{2,63}.[a-z.]{2,5}$");
  let urlParts = regexParse.exec(hostname);
  return hostname.replace(urlParts[0], "").slice(0, -1);
}
export const getLocalizedPathname = (url: string) => {
  if (typeof window !== "undefined") {
    const defaultHost = process.env.NEXT_PUBLIC_URL_BAIMS.replace(/\/$/, "");
    // const browserLocale = getSubdomain(window.location.hostname);
    let countryCode = null;
    try {
      countryCode =
        CountriesList.find(
          (country) =>
            country.name === JSON.parse(localStorage?.getItem(LOCALSTORAGE_IP_INFO) || "{}")?.location?.country?.name
        )?.subdomain || null;
    } catch (e) {}

    if (countryCode) {
      const tmp = document.createElement("a");
      tmp.href = defaultHost;
      tmp.host = (countryCode + ".").replace("kw.", "") + tmp.host;
      return tmp.href.replace(/\/$/, "") + url;
    } else {
      return defaultHost + url;
    }
  }
  return url;
};

export const getLocalizedHomeSubRoute = () => {
  if (typeof window !== "undefined") {
    let countryCode = null;
    try {
      countryCode =
        CountriesList.find(
          (country) =>
            country.name === JSON.parse(localStorage?.getItem(LOCALSTORAGE_IP_INFO) || "{}")?.location?.country?.name
        )?.subroute || null;
    } catch (e) {
      return "";
    }
    return countryCode || "";
  }
};

export const topInstructorNavigate = (url: string, countries: string) => {
  if (typeof window !== "undefined") {
    const defaultHost = process.env.NEXT_PUBLIC_URL_BAIMS.replace(/\/$/, "");
    const countryCode =
      CountriesList.find(
        (country) =>
          country.name === JSON.parse(localStorage?.getItem(LOCALSTORAGE_IP_INFO) || "{}")?.location?.country?.name
      )?.subdomain || null;

    if (countryCode) {
      const tmp = document.createElement("a");
      tmp.href = defaultHost;
      tmp.host = (countryCode + ".").replace("kw.", "") + tmp.host;
      return tmp.href.replace(/\/$/, "") + url;
    } else {
      const tmp = document.createElement("a");
      tmp.href = defaultHost;
      tmp.host = (countries + ".").replace("kw.", "") + tmp.host;
      if (process.env.NEXT_PUBLIC_URL_BAIMS?.includes("dev")) {
        return defaultHost + url;
      } else {
        return tmp.href.replace(/\/$/, "") + url;
      }
    }
  }
  return url;
};

export const algoliaInstructorNavigate = (url: string, country: string) => {
  if (typeof window !== "undefined") {
    const defaultHost = process.env.NEXT_PUBLIC_URL_BAIMS.replace(/\/$/, "");
    const countryLowerCase = country.split(",")[0].toLowerCase() || "";

    let setSubDomain;
    countryLowerCase.includes("kw")
      ? (setSubDomain = "kw")
      : countryLowerCase.includes("ksa")
        ? (setSubDomain = "ksa")
        : countryLowerCase.includes("jordan")
          ? (setSubDomain = "jordan")
          : countryLowerCase.includes("bahrain")
            ? (setSubDomain = "bahrain")
            : countryLowerCase.includes("turkey")
              ? (setSubDomain = "turkey")
              : (setSubDomain = "");

    const tmp = document.createElement("a");
    tmp.href = defaultHost;
    tmp.host = (setSubDomain + ".").replace("kw.", "") + tmp.host;
    if (process.env.NEXT_PUBLIC_URL_BAIMS?.includes("dev")) {
      return defaultHost + url;
    } else {
      return tmp.href.replace(/\/$/, "") + url;
    }
  }
  return url;
};

export const navigateBasedOnCurrency = (url: string, currency: string) => {
  if (typeof window !== "undefined") {
    const defaultHost = process.env.NEXT_PUBLIC_URL_BAIMS.replace(/\/$/, "");

    let setSubDomain;
    currency === "KWD" || currency === "د.ك"
      ? (setSubDomain = "kw")
      : currency === "SAR" || currency === "ر.س"
        ? (setSubDomain = "ksa")
        : currency === "JOD" || currency === "د.أ"
          ? (setSubDomain = "jordan")
          : currency === "BHD" || currency === "د.ب"
            ? (setSubDomain = "bahrain")
            : currency === "TRY" || currency === "ل.ت"
              ? (setSubDomain = "turkey")
              : (setSubDomain = "");

    const tmp = document.createElement("a");
    tmp.href = defaultHost;
    tmp.host = (setSubDomain + ".").replace("kw.", "") + tmp.host;
    if (process.env.NEXT_PUBLIC_URL_BAIMS?.includes("dev")) {
      return defaultHost + url;
    } else {
      return tmp.href.replace(/\/$/, "") + url;
    }
  }
  return url;
};

export const convertArCurrencyEn = (currency: string) => {
  if (["د.ك", "KWD"].includes(currency)) {
    return "kwd";
  } else if (["ر.س", "SAR"].includes(currency)) {
    return "sar";
  } else if (["د.أ", "JOD"].includes(currency)) {
    return "jod";
  } else if (["د.ب", "BHD"].includes(currency)) {
    return "bhd";
  } else if (["ل.ت", "TRY"].includes(currency)) {
    return "try";
  } else {
    return currency;
  }
};

export const getAlgoliaDefaultName = (data: AlgoliaSubject | AlgoliaPackages, locale: string) => {
  let defaultName;
  if (locale === "ar" && data.name.ar) {
    defaultName = "ar";
  } else if (locale === "en" && data.name["en"]) {
    defaultName = "en";
  } else {
    defaultName = Object.keys(data.name)[0];
  }
  return defaultName;
};

export const getAlgoliaDefaultBreadcrumb = (data: AlgoliaSubject | AlgoliaPackages, locale: string) => {
  let defaultBreadcrumbs;
  if (locale === "ar" && data.breadcrumbs_array.ar) {
    defaultBreadcrumbs = "ar";
  } else if (locale === "en" && data.breadcrumbs_array["en"]) {
    defaultBreadcrumbs = "en";
  } else {
    defaultBreadcrumbs = Object.keys(data.breadcrumbs_array)[0];
  }
  return defaultBreadcrumbs;
};

export const getCountryFromPredefinedCountries = (country_code: string) => {
  let theCountry = CountriesList.find(
    (country) =>
      country.subroute.toLowerCase() === country_code.toLowerCase() ||
      country.subdomain.toLowerCase() === country_code.toLowerCase()
  );

  if (theCountry) {
    return {
      country: theCountry.name.toLowerCase(),
      country_code: theCountry.subroute.toLowerCase(),
      emojie: theCountry.emoji,
      algolia_key: theCountry.algolia_key,
      is_actual_user_country: true,
      actual_user_country: null,
      actual_user_country_code: null,
    };
  } else {
    theCountry = CountriesList[0];
    return {
      country: theCountry.name.toLowerCase(),
      country_code: theCountry.subroute,
      emojie: theCountry.emoji,
      algolia_key: theCountry.algolia_key,
      is_actual_user_country: false,
      actual_user_country: country_code.toLowerCase(),
      actual_user_country_code: country_code.toLowerCase(),
    };
  }
};

export const zainPaymentStatusLink = (
  id: string | string[],
  type: string | string[],
  slug: string | string[],
  locale: string,
  status: string
) =>
  `${process.env.NEXT_PUBLIC_APP_URL}/${locale}/payment/status?&status=${status}&id=${id}&slug=${slug}&type=${type}&startwatchUrl=${process.env.NEXT_PUBLIC_APP_URL}/${type}${type !== "package" ? "s" : ""}/${id}`;
